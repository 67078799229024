<template>
  <div class="text">
    <input
      v-model="localValue"
      class="text__input"
      :type="type"
      :placeholder="placeholder"
      :class="classes"
      @change="change"
    />
    <ErrorMessage v-if="validator" v-show="validator.$error">
      <span v-if="validator.$model === undefined"
        >Je hebt bovenstaande vraag nog niet beantwoord.</span
      >
      <span v-else>{{ errorMessage }}</span>
    </ErrorMessage>
  </div>
</template>

<script>
import ErrorMessage from "../ErrorMessage";

export default {
  name: "TextInput",

  components: {
    ErrorMessage
  },

  props: {
    value: {
      required: true
    },
    type: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    validator: {
      type: Object,
      required: false
    },
    errorMessage: {
      type: String,
      default: "We missen nog je antwoord op deze vraag."
    }
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    classes() {
      return {
        "has--value": this.localValue && this.localValue.length > 0
      };
    }
  },

  methods: {
    change() {
      this.$emit("change", this.localValue);
    }
  }
};
</script>

<style scoped lang="scss">
input.text__input {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #f2f2f6;
  border-bottom: 2px solid;
  border-color: $color-purple;
  color: black;
  transition: $transition-speed background-color, $transition-speed border-color;

  &.has--value {
    background-color: white;
    border-color: $color-green;
  }
}
</style>
