<template>
  <div class="page">
    <h1 class="page__title">
      Voltooid
    </h1>
    <p class="page__description">
      Je bestelling is gelukt! Je ontvangt per e-mail een bevestiging en nemen
      binnenkort contact met je op!
    </p>

    <div class="page__content">
      Je kunt nu dit venster sluiten.
    </div>
  </div>
</template>

<script>
export default {
  name: "Bedankt",

  mounted() {
    this.$nextTick(() => {
      this.$store.commit("SET_FINISHED", true);
    });
  },

  beforeRouteLeave(to, from, next) {
    // Last page of process, so no navigation should happen anymore.
    next(false);
  }
};
</script>
