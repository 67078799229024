<template>
  <div v-if="loading" class="loader">
    <svg class="loader__circular" viewBox="25 25 50 50">
      <circle
        class="loader__path"
        cx="50"
        cy="50"
        r="20"
        fill="none"
        stroke-width="2"
        stroke-miterlimit="10"
      />
    </svg>
  </div>
  <div v-else id="app">
    <Navigation />
    <router-view />
  </div>
</template>

<script>
import Navigation from "./components/Navigation";
import { mapState } from "vuex";

export default {
  name: "App",

  components: {
    Navigation
  },

  computed: {
    ...mapState(["loading"])
  },

  mounted() {
    this.$store.dispatch("initApp");
  }
};
</script>

<style lang="scss">
#app {
  position: relative;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}
</style>
