<template>
  <div class="advise">
    <p class="advise__message">
      <span class="advise__svg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22">
          <path
            d="M11 5c3.3 0 6 2.7 6 6 0 2.1-1.1 4.1-3 5.2V18c0 .6-.4 1-1 1H9c-.6 0-1-.4-1-1v-1.8C5.1 14.5 4.1 10.9 5.8 8 6.9 6.1 8.9 5 11 5m2 15v1c0 .6-.4 1-1 1h-2c-.6 0-1-.4-1-1v-1h4m6-10h3v2h-3v-2M0 10h3v2H0v-2M12 0v3h-2V0h2M3.9 2.5L6 4.6 4.6 6.1 2.5 3.9l1.4-1.4M16 4.6l2.1-2.1 1.4 1.4L17.4 6 16 4.6z"
          />
        </svg>
      </span>
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: "AdviseMessage",
  props: {
    //
  }
};
</script>

<style scoped lang="scss">
.advise {
  position: relative;
  margin: 40px 0;

  &__svg {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;

    > svg {
      fill: $color-orange;
    }
  }

  &__message {
    padding-left: 35px;
    color: $color-orange;
    font-size: 16px;
  }
}
</style>
