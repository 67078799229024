<template>
  <button class="question__tooltip" :title="title">
    ?
  </button>
</template>

<script>
import tippy, { animateFill } from "tippy.js";
import "tippy.js/dist/backdrop.css";
import "tippy.js/animations/shift-away.css";
import "tippy.js/dist/tippy.css";
export default {
  name: "Tooltip",

  props: {
    title: {
      default: "Meer informatie"
    },
    text: {
      default: "?"
    },
    content: {
      type: String,
      required: true
    }
  },

  mounted() {
    tippy(this.$el, {
      trigger: "click",
      arrow: false,
      content: this.content,
      animateFill: true,
      plugins: [animateFill],
      theme: "veh"
    });
  }
};
</script>
