<template>
  <div>
    <div class="form__field">
      <div class="form__field__item">
        <label class="checkbox">
          <input v-model="localValue" class="checkbox__input" type="checkbox" />
          <span class="checkbox__marker" />
          <slot />
        </label>
      </div>
    </div>
    <ErrorMessage v-if="validator" v-show="validator.$error">
      <span v-if="validator.$model === undefined"
        >Je hebt bovenstaande vraag nog niet beantwoord.</span
      >
      <span v-else>{{ errorMessage }}</span>
    </ErrorMessage>
  </div>
</template>

<script>
import ErrorMessage from "../ErrorMessage";

export default {
  name: "Checkbox",
  components: {
    ErrorMessage
  },
  props: {
    value: {
      required: true
    },
    errorMessage: {
      type: String,
      default: "We missen nog je antwoord op deze vraag."
    },
    validator: {
      type: Object,
      required: false
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.checkbox {
  display: block;
  width: 100%;
  cursor: pointer;
  transition: background-color $transition-speed;

  &.is--checked {
    background-color: $color-green;
  }

  &__input {
    position: absolute;
    visibility: hidden;
    width: 0;
    height: 0;
    pointer-events: none;
  }

  &__input:checked + &__marker::after {
    opacity: 1;
  }

  &__marker {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    margin-right: 10px;
    border: 2px solid $color-text;
    border-radius: 3px;

    &::after {
      content: "";
      position: absolute;
      top: 2px;
      left: 2px;
      width: 12px;
      height: 12px;
      background-color: $color-green;
      opacity: 0;
      transition: opacity $transition-speed;
    }
  }
}
</style>
