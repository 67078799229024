<template>
  <div class="page">
    <h1 class="page__title">
      Totaaloverzicht
    </h1>
    <p class="page__description">
      Op basis van je ingevulde gegevens, doen wij jou het volgende aanbod.
    </p>

    <div class="loader" v-if="!app.offerLoaded">
      <svg class="loader__circular" viewBox="25 25 50 50">
        <circle
          class="loader__path"
          cx="50"
          cy="50"
          r="20"
          fill="none"
          stroke-width="2"
          stroke-miterlimit="10"
        />
      </svg>
    </div>

    <transition name="fade">
      <div class="page__content" v-show="app.offerLoaded">
        <Offer :cart="app.services" />
      </div>
    </transition>

    <transition name="fade">
      <div v-show="customerFieldsVisible" class="question">
        <h2>Bestelling voltooien</h2>
        <p>
          Geef onderstaande gegevens op om je bestelling af te maken. Uiteraard
          gaan we zorgvuldig om met je persoonsgegevens.
        </p>

        <div class="question__form">
          <h3>Wie ben je?</h3>

          <div class="form__field">
            <div class="form__field__item">
              <TextInput
                type="text"
                placeholder="Voornaam"
                :validator="$root.$v.step4.customer.firstName"
                :value="step4.customer.firstName"
                @input="updateFirstName"
              />
            </div>
            <div class="form__field__item">
              <TextInput
                type="text"
                placeholder="Achternaam"
                :validator="$root.$v.step4.customer.name"
                :value="step4.customer.name"
                @input="updateName"
              />
            </div>
          </div>

          <div class="form__field">
            <div class="form__field__item">
              <TextInput
                type="email"
                placeholder="E-mail adres"
                :validator="$root.$v.step4.customer.email"
                :value="step4.customer.email"
                @input="updateEmail"
              />
            </div>
            <div class="form__field__item">
              <TextInput
                type="text"
                placeholder="Telefoonnummer"
                :validator="$root.$v.step4.customer.phone"
                :value="step4.customer.phone"
                @input="updatePhone"
              />
            </div>
          </div>

          <h3>En wat is je adres?</h3>

          <div class="form__field">
            <div class="form__field__item">
              <TextInput
                type="text"
                placeholder="Postcode"
                :validator="$root.$v.step4.customer.postcode"
                :value="step4.customer.postcode"
                @input="postcodeChanged"
              />
            </div>
            <div class="form__field__item">
              <TextInput
                type="text"
                placeholder="Huisnummer"
                :validator="$root.$v.step4.customer.houseNumber"
                :value="step4.customer.houseNumber"
                @input="updateHouseNumber"
                @change="houseNumberChanged"
              />
            </div>
          </div>
          <div class="form__field">
            <div class="form__field__item">
              <TextInput
                type="text"
                placeholder="Straatnaam"
                :validator="$root.$v.step4.customer.street"
                :value="step4.customer.street"
                @input="updateStreet"
              />
            </div>
            <div class="form__field__item">
              <TextInput
                type="text"
                placeholder="Woonplaats"
                :validator="$root.$v.step4.customer.city"
                :value="step4.customer.city"
                @input="updateCity"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div v-if="app.offerLoaded" class="page__footer">
      <div v-if="!customerFieldsVisible" class="page__footer-action">
        <button
          type="button"
          class="button is--success"
          @click="showCustomerFields"
        >
          Bestelling voltooien
        </button>
      </div>
      <div v-if="customerFieldsVisible" class="page__footer-action">
        <Checkbox
          :value="step4.termsAgreed"
          :error-message="'Je dient nog akkoord te gaan met de voorwaarden.'"
          :validator="$root.$v.step4.termsAgreed"
          @input="updateTerms"
        >
          Op onze dienst zijn de algemene voorwaarden van toepassing, waarmee ik
          akkoord ga.
        </Checkbox>
        <button type="button" class="button is--success" @click="finish">
          Bestellen
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Offer from "../components/Offer";
import Checkbox from "../components/input/Checkbox";
import TextInput from "../components/input/TextInput";
import portaal from "../services/PortaalConnector";
import OrderService from "../services/PortaalConnector";
import { mapActions, mapState } from "vuex";

export default {
  name: "Overzicht",

  components: {
    Offer,
    TextInput,
    Checkbox
  },

  data() {
    return {
      customerFieldsVisible: false
    };
  },

  computed: {
    ...mapState(["app", "step4"])
  },

  created() {
    this.calculateOffer();
  },

  methods: {
    showCustomerFields() {
      this.customerFieldsVisible = true;
      this.$nextTick(() => {
        this.scrollToNextQuestion(300);
      });
    },

    postcodeChanged(value) {
      const cleanValue = value
        .replace(/\W+/g, "")
        .toUpperCase()
        .substring(0, 6);
      this.$store.dispatch("updatePostcode", cleanValue);

      if (
        cleanValue.length === 6 &&
        this.step4.customer.postcode !== cleanValue &&
        this.step4.customer.houseNumber
      ) {
        this.lookupAddress();
      }
    },

    houseNumberChanged(value) {
      if (value && this.step4.customer.postcode.length === 6) {
        this.lookupAddress();
      }
    },

    lookupAddress() {
      OrderService.lookupAddress({
        postcode: this.step4.customer.postcode,
        houseNumber: this.step4.customer.houseNumber
      }).then(({ data }) => {
        this.$store.dispatch("updateStreet", data.street);
        this.$store.dispatch("updateCity", data.city.label);
        this.$store.dispatch("updateConstructionYear", data.year);
        this.$store.dispatch("updateSurface", data.surface);
      });
    },

    async finish(e) {
      this.$root.$v.step4.$touch();

      if (!this.$root.$v.step4.$error) {
        this.setButtonLoading(e.target);
        console.log(portaal);
        portaal
          .confirmOffer(this.$store.state)
          .then(() => {
            this.$router.push({ name: "Bedankt" });
          })
          .catch(e => {
            alert(
              "We konden je bestelling niet plaatsen. Probeer het later opnieuw."
            );
            this.cancelLoaders();
            console.log(e);
          });
      }
    },

    ...mapActions([
      "updateFirstName",
      "updateName",
      "updateEmail",
      "updatePhone",
      "updatePostcode",
      "updateHouseNumber",
      "updateStreet",
      "updateCity",
      "updateTerms",
      "calculateOffer"
    ])
  }
};
</script>
