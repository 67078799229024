<template>
  <div class="page">
    <h1 class="page__title">
      Welk merk CV-ketel heb je?
    </h1>
    <p class="page__description">
      Zo weten onze installateurs welke materialen ze nodig hebben voor
      onderhoud of reparatie.
    </p>
    <div class="page__content">
      <div class="question">
        <div class="question__form">
          <Brands
            :value="step2.brand"
            :is-error="step2.brand === 0"
            @input="updateBrand"
          />
          <ErrorMessage v-show="step2.brand === 0">
            Helaas, onze dienst is niet mogelijk voor jouw CV-ketel
          </ErrorMessage>
        </div>
      </div>

      <div v-if="!$root.$v.step2.brand.$invalid" class="question">
        <h2 class="question__title">
          Welk type {{ selectedProductGroup.title }} CV-ketel heb je?
          <Tooltip
            content="Het typeplaatje van je CV-ketel kan op verschillende plaatsen zitten.
            Meestal aan één van de zijkanten, of achter een klep aan de onderkant."
          />
        </h2>
        <div class="question__description">
          Meestal staat het type op de voorkant van je ketel, of op het
          typeplaatje.
        </div>
        <div class="question__form">
          <div class="form__field">
            <div class="form__field__item">
              <Select
                :value="step2.boiler_type"
                placeholder="Kies keteltype..."
                :options="selectedProductGroupProducts"
                @input="updateType"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-if="!$root.$v.step2.boiler_type.$invalid" class="question">
        <h2 class="question__title">
          Hoe oud is deze CV-ketel?
        </h2>
        <div class="question__description">
          Zo kunnen we een goede inschatting maken van de status van je ketel.
        </div>
        <div class="question__form">
          <RadioGroup
            :value="step2.boiler_is_less_than_10_years"
            :options="[
              { label: '1 t/m 9 jaar', value: true },
              { label: '10 jaar of ouder', value: false }
            ]"
            :validator="$root.$v.step2.boiler_is_less_than_10_years"
            :error-message="'We missen nog je antwoord op deze vraag.'"
            @input="updateBoilerAge"
          />
        </div>
      </div>

      <div
        v-if="!$root.$v.step2.boiler_is_less_than_10_years.$invalid"
        class="question"
      >
        <h2 class="question__title">
          Wanneer was de laatste onderhoudsbeurt?
        </h2>
        <div class="question__description">
          Indien niet bekend, kies je voor langer dan 2 jaar geleden.
        </div>
        <div class="question__form">
          <RadioGroup
            :value="step2.boiler_last_maintenance_less_than_2_years"
            :options="[
              { label: 'In de afgelopen 2 jaar', value: true },
              { label: 'Langer dan 2 jaar geleden', value: false }
            ]"
            :validator="
              $root.$v.step2.boiler_last_maintenance_less_than_2_years
            "
            :error-message="'We missen nog je antwoord op deze vraag.'"
            @input="updateLastMaintenance"
          />
        </div>
      </div>
    </div>
    <div class="page__footer">
      <div class="page__footer-action">
        <button
          v-show="!$root.$v.step2.$invalid"
          type="button"
          class="button"
          @click="finish"
        >
          Besparing & comfort
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RadioGroup from "../components/input/RadioGroup";
import Brands from "../components/input/Brands";
import ErrorMessage from "../components/ErrorMessage";
import Select from "../components/input/Select";
import Tooltip from "../components/Tooltip";
import { mapActions, mapState, mapGetters } from "vuex";

export default {
  name: "Type",
  components: {
    Select,
    ErrorMessage,
    Brands,
    RadioGroup,
    Tooltip
  },

  computed: {
    ...mapState(["app", "step2"]),
    ...mapGetters(["selectedProductGroup", "selectedProductGroupProducts"])
  },

  methods: {
    finish() {
      this.$root.$v.step2.$touch();

      if (!this.$root.$v.step2.$error) {
        this.$router.push({
          name: "Besparing"
        });
      }
    },

    ...mapActions([
      "updateBrand",
      "updateType",
      "updateBoilerAge",
      "updateLastMaintenance"
    ])
  }
};
</script>
