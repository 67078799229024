var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('h1',{staticClass:"page__title"},[_vm._v(" Besparing & Comfort ")]),_c('p',{staticClass:"page__description"},[_vm._v(" Mogelijk kunnen wij bijdragen aan meer besparing of comfort in jouw woning. ")]),_c('div',{staticClass:"page__content"},[_c('div',{staticClass:"question"},[_c('h2',{staticClass:"question__title"},[_vm._v(" Ben je benieuwd of je jouw woning nog kunt optimaliseren op het gebied van comfort en/of besparing? ")]),_c('div',{staticClass:"question__form"},[_c('RadioGroup',{attrs:{"value":_vm.step3.comfortQuestions.customerConsents,"options":[
            { label: 'Ja', value: true },
            { label: 'Nee', value: false }
          ],"validator":_vm.$root.$v.step3.comfortQuestions.customerConsents,"error-message":'We missen nog je antwoord op deze vraag.'},on:{"input":_vm.updateCustomerConsents}})],1)]),(_vm.step3.comfortQuestions.customerConsents === true)?_c('div',[(!_vm.$root.$v.step3.comfortQuestions.customerConsents.$invalid)?_c('div',{staticClass:"question"},[_c('h2',{staticClass:"question__title"},[_vm._v(" Zijn er veel gebruikte ruimtes in je woning die vaak te koud blijven? ")]),_c('div',{staticClass:"question__form"},[_c('RadioGroup',{attrs:{"value":_vm.step3.comfortQuestions.coldRooms,"options":[
              { label: 'Ja', value: true },
              { label: 'Nee', value: false }
            ],"validator":_vm.$root.$v.step3.comfortQuestions.coldRooms},on:{"input":_vm.updateColdRooms}})],1)]):_vm._e(),(!_vm.$root.$v.step3.comfortQuestions.coldRooms.$invalid)?_c('div',{staticClass:"question"},[_c('h2',{staticClass:"question__title"},[_vm._v(" Zijn er veel gebruikte ruimtes in je woning die vaak te warm worden? ")]),_c('div',{staticClass:"question__form"},[_c('RadioGroup',{attrs:{"value":_vm.step3.comfortQuestions.hotRooms,"options":[
              { label: 'Ja', value: true },
              { label: 'Nee', value: false }
            ],"validator":_vm.$root.$v.step3.comfortQuestions.hotRooms},on:{"input":_vm.updateHotRooms}})],1)]):_vm._e(),(!_vm.$root.$v.step3.comfortQuestions.hotRooms.$invalid)?_c('div',{staticClass:"question"},[_c('h2',{staticClass:"question__title"},[_vm._v(" Zijn er ruimtes in je woning die mee worden verwarmd, maar slechts af en toe worden gebruikt? ")]),_c('div',{staticClass:"question__form"},[_c('RadioGroup',{attrs:{"value":_vm.step3.comfortQuestions.littleUsedRooms,"options":[
              { label: 'Ja', value: true },
              { label: 'Nee', value: false }
            ],"validator":_vm.$root.$v.step3.comfortQuestions.littleUsedRooms},on:{"input":_vm.updateLittleUsedRooms}})],1)]):_vm._e(),(!_vm.$root.$v.step3.comfortQuestions.littleUsedRooms.$invalid)?_c('div',{staticClass:"question"},[_c('h2',{staticClass:"question__title"},[_vm._v(" Zijn er ruimtes in je woning die slechts af en toe gebruikt worden en daardoor te koud zijn op het moment dat je ze wilt gebruiken? ")]),_c('div',{staticClass:"question__form"},[_c('RadioGroup',{attrs:{"value":_vm.step3.comfortQuestions.littleUsedRoomsTooCold,"options":[
              { label: 'Ja', value: true },
              { label: 'Nee', value: false }
            ],"validator":_vm.$root.$v.step3.comfortQuestions.littleUsedRoomsTooCold},on:{"input":_vm.updateLittleUsedRoomsTooCold}})],1)]):_vm._e(),(
          !_vm.$root.$v.step3.comfortQuestions.littleUsedRoomsTooCold.$invalid
        )?_c('div',{staticClass:"question"},[_c('h2',{staticClass:"question__title"},[_vm._v(" Is jouw woning op dit moment na-geïsoleerd? ")]),_c('div',{staticClass:"question__description"},[_vm._v(" Is jouw woning later voorzien van extra besparende maatregelen, zoals isolatie? ")]),_c('div',{staticClass:"question__form"},[_c('RadioGroup',{attrs:{"value":_vm.step3.comfortQuestions.houseIsolated,"options":[
              { label: 'Ja', value: true },
              { label: 'Nee', value: false }
            ],"validator":_vm.$root.$v.step3.comfortQuestions.houseIsolated},on:{"input":_vm.updateHouseIsolated}})],1)]):_vm._e(),(
          !_vm.$root.$v.step3.comfortQuestions.houseIsolated.$invalid &&
            _vm.step3.comfortQuestions.houseIsolated === true
        )?_c('div',{staticClass:"question"},[_c('h2',{staticClass:"question__title"},[_vm._v(" Geef aan welke maatregelen zijn uitgevoerd. ")]),_c('div',{staticClass:"question__form"},[_c('CheckboxGroup',{attrs:{"value":_vm.step3.comfortQuestions.isoMeasures,"options":[
              { label: 'Vloerisolatie', value: 'vloerisolatie' },
              { label: 'Dakisolatie', value: 'dakisolatie' },
              { label: 'Spouwmuur-isolatie', value: 'spouwmuurisolatie' },
              { label: 'Dubbel glas of isolatieglas', value: 'glasisolatie' }
            ],"validator":_vm.$root.$v.step3.comfortQuestions.isoMeasures},on:{"input":_vm.updateIsoMeasures}})],1)]):_vm._e()]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        !_vm.$root.$v.step3.$invalid &&
          _vm.step3.comfortQuestions.customerConsents &&
          _vm.recommendWarmteScan
      ),expression:"\n        !$root.$v.step3.$invalid &&\n          step3.comfortQuestions.customerConsents &&\n          recommendWarmteScan\n      "}],staticClass:"question"},[_c('div',{staticClass:"question__form"},[_c('AdviseMessage',[_c('h2',[_vm._v("Advies")]),_vm._v(" Op basis van de gegevens die je hebt opgegeven, adviseren we je om tijdens de onderhoudsbeurt ook een Warmtescan te laten uitvoeren. "),_c('br'),_c('br'),_vm._v(" Ontvang een uitgebreid rapport en ontdek hoe jij kunt besparen en optimaliseren in jouw woning, voor een eenmalig bedrag van 50,- euro. ")]),_c('Offer',{attrs:{"cart":_vm.app.services.filter(function (item) { return !item.fixed; })}})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        !_vm.$root.$v.step3.$invalid &&
          _vm.step3.comfortQuestions.customerConsents &&
          !_vm.recommendWarmteScan
      ),expression:"\n        !$root.$v.step3.$invalid &&\n          step3.comfortQuestions.customerConsents &&\n          !recommendWarmteScan\n      "}],staticClass:"question"},[_c('div',{staticClass:"question__form"},[_c('AdviseMessage',[_c('h2',[_vm._v("Advies")]),_vm._v(" Op basis van de gegevens die je hebt opgegeven, kunnen wij waarschijnlijk geen extra bijdrage leveren aan besparing en comfort in jouw woning."),_c('br'),_c('br'),_vm._v(" Goed voor elkaar dus! ")])],1)])]),_c('div',{staticClass:"page__footer"},[_c('div',{staticClass:"page__footer-action"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$root.$v.step3.$invalid),expression:"!$root.$v.step3.$invalid"}],staticClass:"button",attrs:{"type":"button"},on:{"click":_vm.finish}},[_vm._v(" Bekijk jouw besteloverzicht ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }