import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Start",
    component: () => import(/* webpackChunkName: "start" */ "../views/Start"),
    meta: {
      title: "Start",
      section: "steps",
      validationGroup: "step1"
    }
  },
  {
    path: "/type",
    name: "Type",
    component: () => import(/* webpackChunkName: "type" */ "../views/Type"),
    meta: {
      title: "Type",
      section: "steps",
      validationGroup: "step2"
    }
  },
  {
    path: "/besparing",
    name: "Besparing",
    component: () =>
      import(/* webpackChunkName: "besparing" */ "../views/Besparing"),
    meta: {
      title: "Besparing",
      section: "steps",
      validationGroup: "step3"
    }
  },
  {
    path: "/overzicht",
    name: "Overzicht",
    component: () =>
      import(/* webpackChunkName: "overzicht" */ "../views/Overzicht"),
    meta: {
      title: "Overzicht",
      section: "steps",
      validationGroup: "step4"
    }
  },
  {
    path: "/voltooid",
    name: "Bedankt",
    component: () =>
      import(/* webpackChunkName: "bedankt" */ "../views/Bedankt"),
    meta: {
      title: "Bedankt"
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  }
});

router.afterEach(to => {
  // Get all process-routes
  let routes = router.options.routes.filter(
    route => route.meta.section === "steps"
  );

  // Get the index of the current route
  let current = routes.findIndex(route => route.name === to.name);

  // Get the validator from the root-app.
  let validator = router.app.$v;

  // Filter out all the routes that are after the desired route.
  Object.keys(routes)
    .filter(key => key >= current)
    .forEach(key => delete routes[key]);

  let earlierRoute = routes[current - 1];
  let pageTitle = to.meta.title;

  // Return false if some routes are invalid.
  if (routes.some(route => validator[route.meta.validationGroup].$invalid)) {
    return router.replace(earlierRoute);
  }

  // If this page is running inside an iFrame, signal that page has changed.
  const targetOrigin = store.getters.getSetting("iframe_target_origin");
  if (self !== top && targetOrigin) {
    parent.postMessage("scrollToTop", targetOrigin.value);
  }

  // Update Page Title
  document.title = pageTitle + " · " + router.app.$store.state.app.title;
});

export default router;
