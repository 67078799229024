import axios from "axios";

const api = axios.create({
  timeout: 6000,
  // eslint-disable-next-line no-undef
  baseURL: `${process.env.VUE_APP_HIP_URL}/api/`,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    "X-Requested-With": "XMLHttpRequest"
  }
});

export default {
  getProjectData() {
    // eslint-disable-next-line no-undef
    return api.get(`/data/${process.env.VUE_APP_HIP_PROJECT}`);
  },

  getElements(state) {
    return api.post(`/getElements`, {
      version: state.app.calcModel,
      productGroup: "UWD-SERVICES"
    });
  },

  calculate(state, products) {
    return api.post(`/calculateOffer`, {
      project: state.app.project.ref,
      projectType: state.app.project.type,
      calc: state.app.calcModel,
      details: { ...state.step1, ...state.step2, ...state.step3 },
      cart: products
    });
  },

  confirmOffer(state) {
    const products = state.app.services
      .map(item => {
        return {
          count: item.count,
          element_id: item.id,
          location_id: item.location,
          room_id: 0
        };
      })
      .filter(item => item.count > 0);

    return api.post(`/createOrder`, {
      project: state.app.project.ref,
      projectType: state.app.project.type,
      calc: state.app.calcModel,
      details: { ...state.step1, ...state.step2, ...state.step3 },
      customer: state.step4.customer,
      cart: products
    });
  },

  lookupAddress({ token, postcode, houseNumber }) {
    let url = `/postcode/${postcode}` + (houseNumber ? `/${houseNumber}` : "");

    return api.get(url, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
  }
};
