import { required, requiredIf } from "vuelidate/lib/validators";

/*
 * This is the validations file. It's loaded with the validation package Vuelidate.
 * It describes the (VueX) model and should return the validation logic for each property.
 */

export default {
  step1: {
    hasCV: { checked: value => value === true },
    ownershipCV: { checked: value => value === true }
  },

  step2: {
    brand: { checked: value => value > 0 },
    boiler_type: {
      choiceMade: value => value !== undefined && value !== "null"
    },
    boiler_is_less_than_10_years: { required },
    boiler_last_maintenance_less_than_2_years: { required }
  },

  step3: {
    comfortQuestions: {
      customerConsents: { required },
      coldRooms: { required: requiredIf("customerConsents") },
      hotRooms: { required: requiredIf("customerConsents") },
      littleUsedRooms: { required: requiredIf("customerConsents") },
      littleUsedRoomsTooCold: { required: requiredIf("customerConsents") },
      houseIsolated: { required: requiredIf("customerConsents") },
      isoMeasures: { required: requiredIf("houseIsolated") }
    }
  },
  step4: {
    termsAgreed: { checked: value => value === true },
    customer: {
      firstName: { required },
      name: { required },
      email: { required },
      phone: { required },
      postcode: { required },
      houseNumber: { required },
      street: { required },
      city: { required }
    }
  }
};
