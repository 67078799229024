<template>
  <div>
    <select v-model="localValue" class="select" :class="classes"></select>
  </div>
</template>

<script>
import Choices from "choices.js";

export default {
  name: "Select",
  data() {
    return {
      choices: undefined
    };
  },

  props: {
    value: {
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },
    options: {
      type: Array,
      required: true
    }
  },

  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.scrollToNextQuestion();
      }
    },
    classes() {
      return {
        "has--value": this.localValue !== undefined
      };
    }
  },

  mounted() {
    // Make select-boxes nice.
    this.choices = new Choices(this.$el.querySelector("select"), {
      placeholderValue: this.placeholder,
      itemSelectText: "Kies",
      searchPlaceholderValue: "Zoek...",
      noResultsText: "Niets gevonden..."
    });

    this.setChoices();
  },

  watch: {
    options() {
      this.setChoices();
    }
  },

  methods: {
    setChoices() {
      this.choices.setChoices(
        [
          { value: "null", label: "Maak een keuze...", disabled: true },
          ...this.options
        ],
        "value",
        "label",
        true
      );
      this.choices.setChoiceByValue("null");
      this.localValue = "null";
    }
  }
};
</script>

<style scoped lang="scss">
.select {
  display: block;
  width: 100%;
  padding: 15px;
  background-color: #f2f2f6;
  border-bottom: 2px solid;
  border-color: $color-purple;
  color: black;
  transition: $transition-speed background-color, $transition-speed border-color;

  &.has--value {
    background-color: white;
    border-color: $color-green;
  }
}
</style>
