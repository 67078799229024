import Vue from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import smoothscroll from "smoothscroll-polyfill";
import { validationMixin } from "vuelidate";
import validations from "./validations";
import { mapState } from "vuex";

Vue.config.productionTip = false;

smoothscroll.polyfill();

Array.prototype.shuffle = function() {
  for (let i = this.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * i);
    const temp = this[i];
    this[i] = this[j];
    this[j] = temp;
  }
  return this;
};

Vue.mixin({
  methods: {
    scrollToNextQuestion(px) {
      setTimeout(() => {
        if (px) {
          window.scrollTo({
            top: window.scrollY + px
          });
        } else {
          window.scrollTo({
            top:
              this.$el.getBoundingClientRect().bottom + window.pageYOffset - 98
          });
        }
      }, 500);
    },

    setButtonLoading(el) {
      el.disabled = true;
      el.classList.add("is--loading");
    },

    cancelLoaders() {
      setTimeout(() => {
        let elements = document.querySelectorAll(".is--loading");
        for (let i = 0; i < elements.length; i++) {
          elements[i].classList.remove("is--loading");
          elements[i].disabled = false;
        }
      }, 1000);
    }
  }
});

new Vue({
  router,
  store,
  mixins: [validationMixin],
  computed: {
    ...mapState(["app", "step1", "step2", "step3", "step4"])
  },
  validations,
  render: h => h(App)
}).$mount("#app");
