<template>
  <div class="page">
    <h1 class="page__title">
      Besparing & Comfort
    </h1>
    <p class="page__description">
      Mogelijk kunnen wij bijdragen aan meer besparing of comfort in jouw
      woning.
    </p>
    <div class="page__content">
      <div class="question">
        <h2 class="question__title">
          Ben je benieuwd of je jouw woning nog kunt optimaliseren op het gebied
          van comfort en/of besparing?
        </h2>
        <div class="question__form">
          <RadioGroup
            :value="step3.comfortQuestions.customerConsents"
            :options="[
              { label: 'Ja', value: true },
              { label: 'Nee', value: false }
            ]"
            :validator="$root.$v.step3.comfortQuestions.customerConsents"
            :error-message="'We missen nog je antwoord op deze vraag.'"
            @input="updateCustomerConsents"
          />
        </div>
      </div>

      <div v-if="step3.comfortQuestions.customerConsents === true">
        <div
          v-if="!$root.$v.step3.comfortQuestions.customerConsents.$invalid"
          class="question"
        >
          <h2 class="question__title">
            Zijn er veel gebruikte ruimtes in je woning die vaak te koud
            blijven?
          </h2>
          <div class="question__form">
            <RadioGroup
              :value="step3.comfortQuestions.coldRooms"
              :options="[
                { label: 'Ja', value: true },
                { label: 'Nee', value: false }
              ]"
              :validator="$root.$v.step3.comfortQuestions.coldRooms"
              @input="updateColdRooms"
            />
          </div>
        </div>

        <div
          v-if="!$root.$v.step3.comfortQuestions.coldRooms.$invalid"
          class="question"
        >
          <h2 class="question__title">
            Zijn er veel gebruikte ruimtes in je woning die vaak te warm worden?
          </h2>
          <div class="question__form">
            <RadioGroup
              :value="step3.comfortQuestions.hotRooms"
              :options="[
                { label: 'Ja', value: true },
                { label: 'Nee', value: false }
              ]"
              :validator="$root.$v.step3.comfortQuestions.hotRooms"
              @input="updateHotRooms"
            />
          </div>
        </div>

        <div
          v-if="!$root.$v.step3.comfortQuestions.hotRooms.$invalid"
          class="question"
        >
          <h2 class="question__title">
            Zijn er ruimtes in je woning die mee worden verwarmd, maar slechts
            af en toe worden gebruikt?
          </h2>
          <div class="question__form">
            <RadioGroup
              :value="step3.comfortQuestions.littleUsedRooms"
              :options="[
                { label: 'Ja', value: true },
                { label: 'Nee', value: false }
              ]"
              :validator="$root.$v.step3.comfortQuestions.littleUsedRooms"
              @input="updateLittleUsedRooms"
            />
          </div>
        </div>

        <div
          v-if="!$root.$v.step3.comfortQuestions.littleUsedRooms.$invalid"
          class="question"
        >
          <h2 class="question__title">
            Zijn er ruimtes in je woning die slechts af en toe gebruikt worden
            en daardoor te koud zijn op het moment dat je ze wilt gebruiken?
          </h2>
          <div class="question__form">
            <RadioGroup
              :value="step3.comfortQuestions.littleUsedRoomsTooCold"
              :options="[
                { label: 'Ja', value: true },
                { label: 'Nee', value: false }
              ]"
              :validator="
                $root.$v.step3.comfortQuestions.littleUsedRoomsTooCold
              "
              @input="updateLittleUsedRoomsTooCold"
            />
          </div>
        </div>

        <div
          v-if="
            !$root.$v.step3.comfortQuestions.littleUsedRoomsTooCold.$invalid
          "
          class="question"
        >
          <h2 class="question__title">
            Is jouw woning op dit moment na-geïsoleerd?
          </h2>
          <div class="question__description">
            Is jouw woning later voorzien van extra besparende maatregelen,
            zoals isolatie?
          </div>
          <div class="question__form">
            <RadioGroup
              :value="step3.comfortQuestions.houseIsolated"
              :options="[
                { label: 'Ja', value: true },
                { label: 'Nee', value: false }
              ]"
              :validator="$root.$v.step3.comfortQuestions.houseIsolated"
              @input="updateHouseIsolated"
            />
          </div>
        </div>

        <div
          v-if="
            !$root.$v.step3.comfortQuestions.houseIsolated.$invalid &&
              step3.comfortQuestions.houseIsolated === true
          "
          class="question"
        >
          <h2 class="question__title">
            Geef aan welke maatregelen zijn uitgevoerd.
          </h2>
          <div class="question__form">
            <CheckboxGroup
              :value="step3.comfortQuestions.isoMeasures"
              :options="[
                { label: 'Vloerisolatie', value: 'vloerisolatie' },
                { label: 'Dakisolatie', value: 'dakisolatie' },
                { label: 'Spouwmuur-isolatie', value: 'spouwmuurisolatie' },
                { label: 'Dubbel glas of isolatieglas', value: 'glasisolatie' }
              ]"
              :validator="$root.$v.step3.comfortQuestions.isoMeasures"
              @input="updateIsoMeasures"
            />
          </div>
        </div>
      </div>

      <div
        v-show="
          !$root.$v.step3.$invalid &&
            step3.comfortQuestions.customerConsents &&
            recommendWarmteScan
        "
        class="question"
      >
        <div class="question__form">
          <AdviseMessage>
            <h2>Advies</h2>
            Op basis van de gegevens die je hebt opgegeven, adviseren we je om
            tijdens de onderhoudsbeurt ook een Warmtescan te laten uitvoeren.
            <br />
            <br />
            Ontvang een uitgebreid rapport en ontdek hoe jij kunt besparen en
            optimaliseren in jouw woning, voor een eenmalig bedrag van 50,-
            euro.
          </AdviseMessage>
          <Offer :cart="app.services.filter(item => !item.fixed)" />
        </div>
      </div>
      <div
        v-show="
          !$root.$v.step3.$invalid &&
            step3.comfortQuestions.customerConsents &&
            !recommendWarmteScan
        "
        class="question"
      >
        <div class="question__form">
          <AdviseMessage>
            <h2>Advies</h2>
            Op basis van de gegevens die je hebt opgegeven, kunnen wij
            waarschijnlijk geen extra bijdrage leveren aan besparing en comfort
            in jouw woning.<br /><br />
            Goed voor elkaar dus!
          </AdviseMessage>
        </div>
      </div>
    </div>

    <div class="page__footer">
      <div class="page__footer-action">
        <button
          v-show="!$root.$v.step3.$invalid"
          type="button"
          class="button"
          @click="finish"
        >
          Bekijk jouw besteloverzicht
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RadioGroup from "../components/input/RadioGroup";
import CheckboxGroup from "../components/input/CheckboxGroup";
import AdviseMessage from "../components/AdviseMessage";
import Offer from "../components/Offer";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "Besparing",

  components: {
    AdviseMessage,
    RadioGroup,
    CheckboxGroup,
    Offer
  },

  computed: {
    ...mapState(["app", "step3"]),
    ...mapGetters(["recommendWarmteScan"])
  },

  watch: {
    // recommendWarmteScan(val) {
    //   val
    //     ? this.addOptionalServicesToCart()
    //     : this.removeOptionalServicesToCart();
    // }
  },

  methods: {
    async finish() {
      this.$root.$v.step3.$touch();

      if (!this.$root.$v.step3.$error) {
        await this.$router.push({
          name: "Overzicht"
        });
      }
    },

    ...mapActions([
      "updateComfortQuestions",
      "updateCustomerConsents",
      "updateColdRooms",
      "updateHotRooms",
      "updateLittleUsedRooms",
      "updateLittleUsedRoomsTooCold",
      "updateHouseIsolated",
      "updateIsoMeasures"
    ])
  }
};
</script>
