import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import portaal from "../services/PortaalConnector";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loading: true,
    app: {
      title: "Uw Warmteservice",
      project: {
        ref: undefined,
        type: undefined
      },
      calcModel: undefined,
      token: undefined,
      defaultLocation: undefined,
      productGroups: [],
      services: [],
      offerLoaded: false,
      finished: false,
      settings: []
    },
    step1: {
      hasCV: undefined,
      ownershipCV: undefined
    },
    step2: {
      brand: undefined,
      boiler_type: undefined,
      boiler_is_less_than_10_years: undefined,
      boiler_last_maintenance_less_than_2_years: undefined
    },
    step3: {
      showWarmteScanProductInCart: false,
      comfortQuestions: {
        customerConsents: undefined,
        coldRooms: undefined,
        hotRooms: undefined,
        littleUsedRooms: undefined,
        littleUsedRoomsTooCold: undefined,
        houseIsolated: undefined,
        isoMeasures: []
      }
    },
    step4: {
      termsAgreed: false,
      customer: {
        firstName: undefined,
        name: undefined,
        email: undefined,
        phone: undefined,
        postcode: undefined,
        houseNumber: undefined,
        street: undefined,
        city: undefined,
        constructionYear: undefined,
        surface: undefined
      }
    }
  },
  mutations: {
    SET_ACCESS_TOKEN(state, value) {
      state.app.token = value;
    },

    SET_PROJECT_TYPE(state, value) {
      state.app.project.type = value;
    },

    SET_PROJECT_REF(state, value) {
      state.app.project.ref = value;
    },

    SET_SETTINGS(state, settings) {
      state.app.settings = [];
      settings.forEach(setting => {
        state.app.settings.push(setting);
      });
    },

    SET_CALC_REF(state, value) {
      state.app.calcModel = value;
    },

    SET_PRODUCT_GROUPS(state, value) {
      state.app.productGroups = value;
    },

    UPDATE_HAS_CV(state, value) {
      state.step1.hasCV = value;
    },

    UPDATE_OWNERSHIP_CV(state, value) {
      state.step1.ownershipCV = value;
    },

    UPDATE_BRAND(state, value) {
      state.step2.brand = value;
    },

    UPDATE_TYPE(state, value) {
      state.step2.boiler_type = value;
    },

    UPDATE_BOILER_AGE(state, value) {
      state.step2.boiler_is_less_than_10_years = value;
    },

    UPDATE_LAST_MAINTENANCE(state, value) {
      state.step2.boiler_last_maintenance_less_than_2_years = value;
    },

    UPDATE_COMFORT_QUESTIONS(state, value) {
      state.step3.comfortQuestions = value;
    },

    UPDATE_CUSTOMER_CONSENTS(state, value) {
      state.step3.comfortQuestions.customerConsents = value;
    },

    UPDATE_COLD_ROOMS(state, value) {
      state.step3.comfortQuestions.coldRooms = value;
    },

    UPDATE_HOT_ROOMS(state, value) {
      state.step3.comfortQuestions.hotRooms = value;
    },

    UPDATE_LITTLE_USED_ROOMS(state, value) {
      state.step3.comfortQuestions.littleUsedRooms = value;
    },

    UPDATE_LITTLE_USED_ROOMS_TOO_COLD(state, value) {
      state.step3.comfortQuestions.littleUsedRoomsTooCold = value;
    },

    UPDATE_HOUSE_ISOLATED(state, value) {
      state.step3.comfortQuestions.houseIsolated = value;
    },

    UPDATE_ISO_MEASURES(state, value) {
      state.step3.comfortQuestions.isoMeasures = value;
    },

    SET_SERVICES(state, serviceGroup) {
      state.app.services = [];

      serviceGroup.forEach(group => {
        group.products.forEach(service => {
          state.app.services.push({
            ...service,
            location: state.app.defaultLocation,
            count: service.fixed ? 1 : 0,
            price: undefined
          });
        });
      });
    },

    UPDATE_SERVICE_PRICE(state, product) {
      const item = state.app.services.find(
        item => item.reference === product.Reference
      );

      if (item) {
        let price;

        if (product.SalesPriceInclVAT) {
          price = product.SalesPriceInclVAT;
        } else {
          const priceSystemPrice = product.Price.find(
            price => price.PriceSystem === "A"
          );
          price = priceSystemPrice.SalesPrice * 1.21;
        }

        item.price = Number(price)
          .toFixed(2)
          .replace(".", ",");
      }
    },

    UPDATE_SERVICE_COUNT(state, { product, count }) {
      const item = state.app.services.find(
        rule => rule.reference === product.reference
      );

      item.count = count;
    },

    UPDATE_NAME(state, value) {
      state.step4.customer.name = value;
    },

    UPDATE_FIRST_NAME(state, value) {
      state.step4.customer.firstName = value;
    },

    UPDATE_EMAIL(state, value) {
      state.step4.customer.email = value;
    },

    UPDATE_PHONE(state, value) {
      state.step4.customer.phone = value;
    },

    UPDATE_POSTCODE(state, value) {
      state.step4.customer.postcode = value;
    },

    UPDATE_HOUSENUMBER(state, value) {
      state.step4.customer.houseNumber = value;
    },

    UPDATE_STREET(state, value) {
      state.step4.customer.street = value;
    },

    UPDATE_CITY(state, value) {
      state.step4.customer.city = value;
    },

    UPDATE_CONSTRUCTION_YEAR(state, value) {
      state.step4.customer.constructionYear = value;
    },

    UPDATE_SURFACE(state, value) {
      state.step4.customer.surface = value;
    },

    UPDATE_TERMS(state, value) {
      state.step4.termsAgreed = value;
    },

    SET_LOCATION(state, value) {
      state.app.defaultLocation = value;
    },

    SET_OFFER_LOADED(state, value) {
      state.app.offerLoaded = value;
    },

    SET_FINISHED(state, value) {
      state.app.finished = value;
    }
  },
  actions: {
    async initApp({ dispatch }) {
      await dispatch("loadAppData");
      dispatch("startIframeParentNotifier");
    },

    async loadAppData({ state, commit, dispatch }) {
      try {
        const { data } = await portaal.getProjectData();

        // Set Project Ref
        commit("SET_PROJECT_REF", data.project.reference);

        // Set Project Type
        commit("SET_PROJECT_TYPE", data.project.type);

        // Set Calculation Model Ref
        commit("SET_CALC_REF", data.calculationModel.reference);

        // Set Settings
        commit("SET_SETTINGS", data.settings);

        // Set the default location (Mendix needs this)
        if (data.locations.length) {
          commit("SET_LOCATION", data.locations[0].id);
        }

        const productGroups = data["groups"]
          .map(group => {
            return {
              ...group,
              image: group.image,
              products: data.elements.filter(
                element => element.group === group.id
              )
            };
          })
          .shuffle();

        // Set Productgroups
        commit(
          "SET_PRODUCT_GROUPS",
          productGroups.filter(group => group.type === "ketel")
        );

        // Set Servicegroups (a.k.a. the orderable services, like a maintenance job.)
        commit(
          "SET_SERVICES",
          productGroups.filter(group => group.type === "service")
        );

        // Get the prices for the products.
        dispatch("getElements");
      } catch (e) {
        alert("Er is een fout opgetreden bij het laden van de applicatie.");
        console.log(e);
      }
      state.loading = false;
    },

    // If this page is running inside an iFrame
    startIframeParentNotifier({ getters }) {
      // If this page is running inside an iFrame, signal that page has changed.
      const targetOrigin = getters.getSetting("iframe_target_origin");

      if (self !== top && targetOrigin) {
        console.log("iFramed Child App");
        const app = document.querySelector("#app");

        setInterval(function() {
          parent.postMessage(
            "appHeight:" + app.getBoundingClientRect().height,
            targetOrigin.value
          );
        }, 400);
      } else {
        console.log("Fullscreen Parent App");
      }
    },

    updateHasCV({ commit }, value) {
      commit("UPDATE_HAS_CV", value);
    },

    updateOwnershipCV({ commit }, value) {
      commit("UPDATE_OWNERSHIP_CV", value);
    },

    updateBrand({ commit }, value) {
      commit("UPDATE_BRAND", parseInt(value, 10));
    },

    updateType({ commit }, value) {
      const intValue = parseInt(value, 10);
      commit("UPDATE_TYPE", isNaN(intValue) ? undefined : intValue);
    },

    updateBoilerAge({ commit }, value) {
      commit("UPDATE_BOILER_AGE", value);
    },

    updateLastMaintenance({ commit }, value) {
      commit("UPDATE_LAST_MAINTENANCE", value);
    },

    updateComfortQuestions({ commit }, value) {
      commit("UPDATE_COMFORT_QUESTIONS", value);
    },

    updateCustomerConsents({ commit }, value) {
      commit("UPDATE_CUSTOMER_CONSENTS", value);
    },

    updateColdRooms({ commit }, value) {
      commit("UPDATE_COLD_ROOMS", value);
    },

    updateHotRooms({ commit }, value) {
      commit("UPDATE_HOT_ROOMS", value);
    },

    updateLittleUsedRooms({ commit }, value) {
      commit("UPDATE_LITTLE_USED_ROOMS", value);
    },

    updateLittleUsedRoomsTooCold({ commit }, value) {
      commit("UPDATE_LITTLE_USED_ROOMS_TOO_COLD", value);
    },

    updateHouseIsolated({ commit }, value) {
      commit("UPDATE_HOUSE_ISOLATED", value);
    },

    updateIsoMeasures({ commit }, value) {
      commit("UPDATE_ISO_MEASURES", value);
    },

    updateItemInCart({ commit }, changes) {
      commit("UPDATE_SERVICE_COUNT", changes);
    },

    getElements({ state, commit }) {
      portaal.getElements(state).then(({ data }) => {
        data.forEach(element => {
          commit("UPDATE_SERVICE_PRICE", element);
        });
      });
    },

    // Gets the prices of the services and the total offer price.
    calculateOffer({ commit, state }) {
      const products = state.app.services.map(item => {
        return {
          element_id: item.id,
          location_id: item.location,
          count: 1,
          room_id: 0
        };
      });

      portaal
        .calculate(state, products)
        .then(({ data }) => {
          data.offer.Elements.forEach(element => {
            commit("UPDATE_SERVICE_PRICE", element);
          });
          commit("SET_OFFER_LOADED", true);
        })
        .catch(e => {
          alert(
            "Wegens een storing, konden we je aanbod niet ophalen. Probeer het later nog eens."
          );
          console.log(e);
        });
    },

    updateFirstName({ commit }, value) {
      commit("UPDATE_FIRST_NAME", value);
    },

    updateName({ commit }, value) {
      commit("UPDATE_NAME", value);
    },

    updateEmail({ commit }, value) {
      commit("UPDATE_EMAIL", value);
    },

    updatePhone({ commit }, value) {
      commit("UPDATE_PHONE", value);
    },

    updatePostcode({ commit }, value) {
      commit("UPDATE_POSTCODE", value);
    },

    updateHouseNumber({ commit }, value) {
      commit("UPDATE_HOUSENUMBER", value);
    },

    updateStreet({ commit }, value) {
      commit("UPDATE_STREET", value);
    },

    updateCity({ commit }, value) {
      commit("UPDATE_CITY", value);
    },

    updateConstructionYear({ commit }, value) {
      commit("UPDATE_CONSTRUCTION_YEAR", value);
    },

    updateSurface({ commit }, value) {
      commit("UPDATE_SURFACE", value);
    },

    updateTerms({ commit }, value) {
      commit("UPDATE_TERMS", value);
    }
  },

  modules: {},

  getters: {
    selectedProductGroup: state => {
      return state.app.productGroups.find(
        group => group.id === state.step2.brand
      );
    },

    selectedProductGroupProducts: (state, getters) => {
      return getters.selectedProductGroup.products.map(product => {
        return {
          label: product.title,
          value: product.id
        };
      });
    },

    recommendWarmteScan: state => {
      return !!(
        state.step3.comfortQuestions.coldRooms ||
        state.step3.comfortQuestions.hotRooms ||
        state.step3.comfortQuestions.littleUsedRooms ||
        state.step3.comfortQuestions.littleUsedRoomsTooCold ||
        !state.step3.comfortQuestions.houseIsolated
      );
    },

    getSetting: state => key => {
      return state.app.settings.find(setting => {
        return setting.key === key;
      });
    }
  },

  plugins: [
    createPersistedState({
      key: "app-state",
      paths: ["step1", "step2", "step3"],
      reducer(val) {
        if (val.app.finished === true) {
          // return empty state when tool has finished
          return {};
        }

        return val;
      }
    })
  ]
});
