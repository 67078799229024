<template>
  <div class="brands" :class="classes">
    <div
      v-for="group in app.productGroups"
      :key="group.id"
      class="brand"
      :class="{ 'is--selected': localValue === group.id }"
      @click="localValue = group.id"
    >
      <figure class="brand__figure">
        <img :src="group.image" :alt="group.title" class="brand__image" />
      </figure>
    </div>

    <div
      class="brand"
      :class="{ 'is--selected': localValue === 0 }"
      @click="localValue = 0"
    >
      Geen van deze merken
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "Brands",
  props: {
    value: {
      required: true
    },
    isError: {
      type: Boolean,
      required: false
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
        this.scrollToNextQuestion();
      }
    },
    classes() {
      return {
        "has--value": this.localValue !== undefined,
        "is--error": this.isError === true
      };
    },
    ...mapState(["app"])
  }
};
</script>

<style scoped lang="scss">
.brands {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -20px;

  &.is--error > .brand:last-child {
    background-color: $color-red;
    color: white;
  }

  &.has--value > .brand {
    opacity: 0.3;
  }
}

.brand {
  width: calc(50% - 40px);
  margin: 20px;
  padding: 15px;
  background-color: white;
  box-shadow: 0 5px 10px #00000029;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  transition: $transition-speed background-color, $transition-speed color,
    $transition-speed opacity;

  &:last-child {
    width: 100%;
    padding: 25px;
  }

  &.is--selected {
    opacity: 1 !important;
  }

  &__figure {
    height: 100%;
    width: 100%;
  }

  &__image {
    position: relative;
    top: 50%;
    max-width: 100%;
    transform: translateY(-50%);
    height: 60px;
  }

  @include respond-to("xs") {
    width: 100%;

    &__image {
      height: 50px;
    }
  }
}
</style>
