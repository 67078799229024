<template>
  <div class="offer">
    <div v-for="(item, cartKey) in cart" :key="cartKey" class="row">
      <div>
        <h2 class="offer__title">
          {{ item.title }}
        </h2>
        <div v-if="item.subtitle" class="offer__subtitle">
          {{ item.subtitle }}
        </div>
        <div v-if="item.usps" class="offer__usps">
          <ul>
            <li v-for="(usp, uspKey) in item.usps" :key="uspKey">
              {{ usp }}
            </li>
          </ul>
        </div>
        <div v-if="!item.fixed">
          <Checkbox :value="item.count" @input="updateCartRule($event, item)">
            Ja, ik wil {{ item.title }} direct meebestellen.
          </Checkbox>
        </div>
      </div>
      <transition name="fade">
        <div v-if="item.price && item.count > 0" style="min-width: 6em;">
          <div class="offer__price">
            &euro; {{ item.price.split(",")[0] }},
            <sup>{{ item.price.split(",")[1] }}</sup>
          </div>
          <div class="offer__price__subtitle">
            incl. BTW
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Checkbox from "./input/Checkbox";
import { mapActions } from "vuex";

export default {
  name: "Offer",

  components: {
    Checkbox
  },

  props: {
    cart: {
      type: Array,
      required: true
    }
  },

  methods: {
    updateCartRule(e, product) {
      this.updateItemInCart({
        product,
        count: e ? 1 : 0
      });
    },

    ...mapActions(["updateItemInCart"])
  }
};
</script>

<style lang="scss" scoped>
.offer {
  margin: 20px 0 30px;
  background-color: white;
  box-shadow: 0 5px 10px #00000029;
  border-radius: 5px;
  transition: $transition-speed background-color, $transition-speed color,
    $transition-speed opacity;

  .row {
    display: flex;
    border-top: 1px solid #eee;
    padding: 20px;

    &:first-child {
      border: none;
    }

    > div {
      flex-grow: 1;
    }
  }

  &__title {
    font-family: $font-main-slab;
    font-weight: 500;
    letter-spacing: -1px;
    font-size: 24px;
    margin: 0;
  }

  &__subtitle {
    font-size: 15px;
    color: $black;
  }

  &__usps {
    margin-top: 10px;

    ul li {
      font-size: 14px;
      position: relative;
      padding-left: 20px;
      &:before {
        content: url(~@/assets/checkmark.svg);
        position: absolute;
        top: 5px;
        left: 7px;
        width: 12px;
        height: 12px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__price {
    font-family: $font-main-slab;
    font-weight: 700;
    text-align: right;
    letter-spacing: -1px;
    font-size: 24px;
    color: $black;
    sup {
      top: -6px;
      font-size: 70%;
    }
  }

  &__price__subtitle {
    font-size: 14px;
    text-align: right;
    color: $black;
  }
}
</style>
