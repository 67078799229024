<template>
  <div class="page">
    <h1 class="page__title">
      Onderhoudsbeurt aanvragen
    </h1>
    <p class="page__description">
      Hier kun je eenvoudig een onderhoudsbeurt voor jouw CV ketel aanvragen.
    </p>
    <div class="page__content">
      <div class="question">
        <h2 class="question__title">
          Benieuwd of jouw huis geschikt is voor onze dienst?
        </h2>
        <p class="question__description">
          Om te beginnen willen we graag weten of je jouw huis verwarmt met een
          CV-ketel.
        </p>
        <div class="question__form">
          <RadioGroup
            :value="step1.hasCV"
            :options="[
              { label: 'Ja, ik heb een CV-ketel', value: true },
              { label: 'Nee, ik heb een andere warmtebron', value: false }
            ]"
            :validator="$root.$v.step1.hasCV"
            :error-message="
              'Helaas, onze dienst is momenteel nog niet mogelijk met een andere warmtebron dan een CV-ketel.'
            "
            direction="row"
            @input="updateHasCV"
          />
        </div>
      </div>

      <div v-show="!$root.$v.step1.hasCV.$invalid" class="question">
        <h2 class="question__title">
          Is de CV-ketel jouw eigendom?
        </h2>
        <div class="question__form">
          <RadioGroup
            :value="step1.ownershipCV"
            :options="[
              { label: 'Ja, de CV-ketel is mijn eigendom', value: true },
              { label: 'Nee, ik huur de CV-ketel', value: false }
            ]"
            :validator="$root.$v.step1.ownershipCV"
            :error-message="
              'Helaas, onze dienst is alleen van toepassing op koop-ketels.'
            "
            direction="row"
            @input="updateOwnershipCV"
          />
        </div>
      </div>
    </div>
    <div class="page__footer">
      <div class="page__footer-action">
        <button
          v-show="!$root.$v.step1.$invalid"
          type="button"
          class="button"
          @click="finish"
        >
          Bepaal jouw type CV-ketel
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import RadioGroup from "../components/input/RadioGroup";
import { mapActions, mapState } from "vuex";

export default {
  name: "Start",
  components: {
    RadioGroup
  },

  computed: {
    ...mapState(["step1"])
  },

  methods: {
    finish() {
      this.$root.$v.step1.$touch();

      if (!this.$root.$v.step1.$error) {
        this.$router.push({
          name: "Type"
        });
      }
    },

    ...mapActions(["updateHasCV", "updateOwnershipCV"])
  }
};
</script>
