<template>
  <nav class="nav">
    <div class="nav__container">
      <div class="nav__steps">
        <div
          v-for="route in routes"
          :key="route.name"
          class="nav__step"
          :class="{ 'is--active': isActive(route), 'is--done': isDone(route) }"
        >
          <router-link
            v-if="isDone(route)"
            class="nav__step-title"
            :to="{ name: route.name }"
          >
            {{ route.meta.title }}
          </router-link>
          <span v-else class="nav__step-title">{{ route.meta.title }}</span>
          <div class="nav__step-dot" />
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Navigation",
  data() {
    return {
      routes: this.$router.options.routes.filter(
        route => route.meta.section === "steps"
      )
    };
  },
  methods: {
    isDone(route) {
      if ("validationGroup" in route.meta) {
        return !this.$root.$v[route.meta.validationGroup].$invalid;
      }
      return false;
    },

    isActive(route) {
      return route.name === this.$route.name;
    }
  }
};
</script>

<style scoped lang="scss">
.nav {
  display: block;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: 800px;
  padding: 30px;
  border-bottom: 1px solid #e1d9d9;
  background-color: white;
  z-index: 10;

  &__container {
    //
  }

  &__steps {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  &__step {
    flex-basis: 100%;
    text-align: center;

    &.is--active &-dot {
      background: #e0d7d7 linear-gradient(90deg, #03c964 50%, #e0d7d7 50%);

      &::after {
        background-color: #ffb457;
        border-color: #f57f00;
      }
    }

    &.is--done &-dot {
      background: #03c964;

      &::after {
        background-color: #0ce677;
        border-color: #03c964;
      }
    }
  }

  &__step-title {
    display: block;
    margin-bottom: 20px;
    font-size: 14px;
    white-space: nowrap;
  }

  &__step-dot {
    display: block;
    position: relative;
    height: 2px;
    background-color: #e0d7d7;
    transition: $transition-speed background-color;

    &::after {
      content: "";
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate(-50%, 9px);
      width: 20px;
      height: 20px;
      background-color: white;
      border-radius: 50%;
      border: 2px solid #e0d7d7;
      transition: $transition-speed border-color,
        $transition-speed background-color;
    }
  }
}
</style>
