<template>
  <div>
    <div
      class="form__field"
      :class="{ 'form__field--row': direction === 'row' }"
    >
      <div
        v-for="option in options"
        :key="option.value"
        class="form__field__item"
      >
        <label
          class="radio"
          :class="{
            'is--faded': localValue !== undefined && localValue !== option.value
          }"
        >
          <input
            v-model="localValue"
            class="radio__input"
            type="radio"
            :value="option.value"
          />
          <span class="radio__marker" />
          {{ option.label }}
        </label>
      </div>
    </div>
    <ErrorMessage v-if="validator" v-show="validator.$error">
      <span v-if="validator.$model === undefined"
        >Je hebt bovenstaande vraag nog niet beantwoord.</span
      >
      <span v-else>{{ errorMessage }}</span>
    </ErrorMessage>
  </div>
</template>

<script>
import ErrorMessage from "../ErrorMessage";

export default {
  name: "RadioGroup",
  components: {
    ErrorMessage
  },
  props: {
    value: {
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    errorMessage: {
      type: String,
      required: false
    },
    validator: {
      type: Object,
      required: false
    },
    direction: {
      type: String,
      required: false
    }
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);

        if (this.validator) {
          this.validator.$touch();
        }

        this.scrollToNextQuestion();
      }
    }
  }
};
</script>

<style scoped lang="scss">
.radio {
  display: block;
  width: 100%;
  padding: 20px 25px;
  background-color: $color-purple;
  color: white;
  border-radius: 5px;
  box-shadow: 0 5px 5px #00000029;
  cursor: pointer;
  transition: opacity $transition-speed, background-color $transition-speed;
  user-select: none;

  &.is--faded {
    opacity: 0.4;
    box-shadow: none;
  }

  &.is--error {
    background-color: $color-red;
  }

  &__input {
    position: absolute;
    visibility: hidden;
    width: 0;
    height: 0;
    pointer-events: none;
  }

  &__input:hover + &__marker::after {
    opacity: 0.7;
  }

  &__input:checked + &__marker::after {
    opacity: 1;
  }

  &__marker {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    margin-right: 15px;
    border: 2px solid white;
    border-radius: 50%;

    &::after {
      content: "";
      position: absolute;
      top: 3px;
      left: 3px;
      width: 10px;
      height: 10px;
      background-color: white;
      border-radius: 50%;
      opacity: 0;
      transition: opacity $transition-speed;
    }
  }
}
</style>
