<template>
  <div class="error">
    <p class="error__message">
      <span class="error__svg">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 19">
          <path d="M0 19h22L11 0 0 19zm12-3h-2v-2h2v2zm0-4h-2V8h2v4z" />
        </svg>
      </span>
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: {
    //
  }
};
</script>

<style scoped lang="scss">
.error {
  margin: 10px 0;

  &__svg {
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    margin-right: 10px;

    > svg {
      fill: $color-red;
    }
  }

  &__message {
    color: $color-red;
  }
}
</style>
